import request from '@/util/request';

export function roleCreate(data) {
    return request({
        url: `api/v1/account/role/create`,
        method: 'post',
        data
    })
}

export function roleModules() {
    return request({
        url: `api/v1/account/role/modules`,
        method: 'get',

    })
}

export function roleUpdate(data) {
    return request({
        url: `api/v1/account/role/update`,
        method: 'post',
        data
    })
}

export function roleList() {
    return request({
        url: `api/v1/account/role/list`,
        method: 'get',
    })
}


export function roleUserUnion(params) {
    return request({
        url: `api/v1/account/role/user/union`,
        method: 'get',
        params
    })
}

export function roleUserList(params) {
    return request({
        url: `api/v1/account/role/user/list`,
        method: 'get',
        params
    })
}

export function roleUnbindUserUnion(params) {
    return request({
        url: `api/v1/account/role/unbindUser/union`,
        method: 'get',
        params
    })
}

export function roleUnbindUserList(params) {
    return request({
        url: `api/v1/account/role/unbindUser/list`,
        method: 'get',
        params
    })
}

export function roleBindUser(data) {
    return request({
        url: `/api/v1/account/role/bindUser`,
        method: 'POST',
        data
    })
}

export function roleDelete(params) {
    return request({
        url: `/api/v1/account/role/delete`,
        method: 'get',
        params
    })
}

