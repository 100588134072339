<template>
  <div class="delivery-allocated-users">
    <div class="delivery-allocated-device-data-list">
      <el-table :data="users"
                ref="multipleTable"
                max-height="420px"
                @select="listChange"
                @select-all="handleBatch">
        <empty slot="empty"></empty>
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column label="用户名称" align="center" prop="name"></el-table-column>
        <el-table-column label="用户手机号" align="center" prop="phone"></el-table-column>
        <el-table-column label="注册时间" align="center">
          <template slot-scope="scope">
            <span>{{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>

</template>

<script>
  import {roleUnbindUserUnion, roleUnbindUserList} from '@/api/account/role'

  export default {
    name: '',
    props: {},
    components: {},
    computed: {},
    mounted: function () {
    },
    data: function () {
      return {
        deliveries: [],
        users: [],
        products: [],
        loading: false,
        pagination: {
          productUuid: '',
          uuid: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
        allocatedEnterprise: {},
        delivery: {
          users: []
        }
      }
    },

    methods: {
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        roleUnbindUserList(this.pagination).then(res => {
          this.users = res.data || []
          this.$nextTick(function () {
            this.judoCheck(this.users)
          })
        })
      },

      judoCheck(users) {
        for (let j = 0; j < users.length; j++) {
          users[j].checked = false
        }

        for (let j = 0; j < users.length; j++) {
          for (let i = 0; i < this.delivery.users.length; i++) {
            if (users[j].uuid === this.delivery.users[i]) {
              this.$refs.multipleTable.toggleRowSelection(users[j])
              break
            }
          }
        }
        return users
      },

      handleBatch(value) {
        for (let j = 0; j < this.users.length; j++) {
          for (let i = 0; i < this.delivery.users.length; i++) {
            if (this.users[j].uuid === this.delivery.users[i]) {
              this.delivery.users.splice(i, 1)
            }
          }
        }

        if (value.length !== 0) {
          for (let j = 0; j < value.length; j++) {
            this.delivery.users.push(value[j].uuid)
          }
        }
      },


      listChange(selection) {
        this.handleBatch(selection)
      },


      loadUser(uuid) {
        let vue = this
        this.pagination.uuid = uuid
        roleUnbindUserUnion(this.pagination).then(res => {
          vue.users = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },


      userAllocate() {
        return this.delivery.users
      },
    },
  }
</script>

<style lang="scss">
  .delivery-allocated-users {
    padding: $padding;
    display: flex;
    flex-direction: column;

    .delivery-allocated-device-title {
      .delivery-allocated-device-input-filter {
        input {
          border: none;
          border-bottom: 1px solid;
          border-radius: 0;
        }
      }

      .delivery-allocated-device-status-filter {
        max-width: 124px;

        .el-input--suffix .el-input__inner {
          padding-right: 0;
        }
      }

      .more {
        font-size: .6rem;
        height: 28px !important;
        line-height: 28px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        span {
          cursor: pointer;
        }
      }
    }

    .delivery-allocated-device-data-list {
      margin-top: $padding;
      flex: 1;

      .delivery-allocated-device-status {
        .delivery-allocated-device-info-img {
        }

        .delivery-allocated-device-info-detail {

          p {
            margin-bottom: $padding;
          }

          p:first-child {
            font-size: .8rem;
          }
        }
      }

      .delivery-allocated-device-address {
        p {
          margin-bottom: $padding;
        }
      }

      .el-table {
        thead {
          tr {
            th {
              border-bottom: 1px solid;
            }
          }
        }

      }

      .el-table--group::after, .el-table--border::after, .el-table::before {
        background-color: transparent;
      }

      .el-table__row:hover {
      }

    }

    .pagination {
      margin: $padding;
    }
  }

</style>
