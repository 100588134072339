<template>
  <div class="role">
    <el-row class="role-title" :gutter="4">
      <el-col :span="18">
        <span class="h3">角色列表</span>
        <el-button size="mini" type="primary" @click="handleRoleCreate" plain>新增角色</el-button>
      </el-col>

    </el-row>
    <el-table :data="roles" class="pay-container">
      <empty slot="empty"></empty>
      <el-table-column label="角色名称" align="center" prop="name"></el-table-column>
      <el-table-column label="相关模块" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.moduleName">{{scope.row.moduleName}}</span>
          <span v-else>全部模块</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <p class="role-operate">
                        <span class="user-edit" v-show="scope.row.moduleName"
                              @click="handleRoleUpdate(scope.row)">编辑信息</span>
            <span class="user-edit" @click="handleRoleList(scope.row)">查看成员</span>
            <span class="user-edit" @click="handleUnbindUserList(scope.row)">绑定成员</span>
            <span v-show="scope.row.moduleName" class="user-delete" @click="handleDelete(scope.row)">删除</span>
          </p>

        </template>
      </el-table-column>
    </el-table>

    <c-dialog class="dialog" :visible.sync="roleCreateVisible" :width="'48rem'" :height="'36rem'"
              :outer-close="false"
              title="角色信息">
      <el-button slot="title" size="mini" type="primary" @click="handleCreateRole">保存</el-button>

      <div class="role-container">
        <el-form label-width="80px">
          <el-form-item label="角色名称">
            <el-input v-model="role.name"></el-input>
          </el-form-item>

          <el-form-item label="角色模块">
            <div class="module-container">
              <div class="module-sort-item" v-for="(moduleSort, key) in moduleSorts" :key="key">
                <p class="module-sort-item-title">{{moduleSort.name}}</p>

                <div class="module-sort-item-container">
                  <el-checkbox v-for="module in moduleSort.modules" :key="module.uuid"
                               :label="module.name" v-model="module.isCheck">
                    {{module.name}}
                  </el-checkbox>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>

    </c-dialog>


    <c-dialog :visible.sync="roleUserVisible" :width="'50rem'" :height="'37.5rem'"
              :outer-close="false"
              title="已绑定角色用户">
      <users ref="bindUser"></users>

    </c-dialog>

    <c-dialog :visible.sync="roleUnbindUserVisible" :width="'50rem'" :height="'37.5rem'"
              :outer-close="false"
              title="未绑定角色用户">
      <el-button slot="title" size="mini" type="primary" @click="handleUpdateUserRole">保存</el-button>
      <unbind-user ref="unbindUser"></unbind-user>
    </c-dialog>

  </div>
</template>

<script>
  import CDialog from '@/components/Dialog'
  import Users from './users'
  import UnbindUser from '@/components/UnbindUser'
  import {roleCreate, roleList, roleUpdate, roleBindUser, roleDelete, roleModules} from '@/api/account/role'

  export default {
    name: '',
    components: {
      UnbindUser,
      CDialog,
      Users
    },
    activated: function () {
      this.loadRole()
    },
    data: function () {
      return {
        roles: [],
        pagination: {
          start: 0,
          curPage: 1,
          limit: 8,
          total: 0,
          name: ''
        },
        role: {
          uuid: '',
          name: '',
          moduleUuid: [],
          moduleName: ''
        },
        moduleSorts: [],
        roleCreateVisible: false,
        roleUserVisible: false,
        roleUnbindUserVisible: false,
        roleUuid: ''

      }
    },
    filters: {},
    methods: {

      loadRole() {
        roleList().then(res => {
          this.roles = res.data || []
        })
        roleModules().then(res => {
          this.moduleSorts = res.data || []
        })
      },
      handleRoleCreate() {
        this.role = {
          uuid: '',
          name: '',
          moduleUuid: [],
          moduleName: ''
        }
        this.moduleSorts.map((item, index) => {
          item.modules.map((module, key) => {
            this.$set(this.moduleSorts[index].modules[key], 'isCheck', false);
          })
        })
        this.roleCreateVisible = true
      },

      handleRoleUpdate(role) {
        this.role.name = role.name
        this.role.uuid = role.uuid
        this.moduleSorts.map((item, index) => {
          item.modules.map((module, key) => {
            this.$set(this.moduleSorts[index].modules[key], 'isCheck', role.moduleName.indexOf(module.name) >= 0);
          })
        })
        this.roleCreateVisible = true
      },

      handleChangeRole(uuid) {
        roleSetDefault({uuid: uuid}).then(() => {
          this.roles.map(role => {
            role.isDefault = role.uuid === uuid
          })
          this.$message.success('设置成功')
        })
      },

      handleRoleList(role) {
        this.roleUserVisible = true
        this.$nextTick(() => {
          this.$refs.bindUser.loadUser(role.uuid)
        })

      },

      handleUnbindUserList(role) {
        this.roleUnbindUserVisible = true
        this.roleUuid = role.uuid
        this.$nextTick(() => {
          this.$refs.unbindUser.loadUser(role.uuid)
        })

      },

      handleUpdateUserRole() {
        let userUuid = this.$refs.unbindUser.userAllocate() || []
        if (userUuid.length === 0) {
          this.$message.warning('请选取需要分配的成员')
          return
        }

        roleBindUser({uuid: this.roleUuid, userUuid: userUuid}).then(res => {
          this.$message.success('设置成功')
          this.roleUnbindUserVisible = false
        })
      },

      handleCreateRole() {
        if (!this.role.name) {
          this.$message.warning('请输入角色名称')
          return
        }
        let moduleName = []

        this.moduleSorts.map(item => {
          item.modules.map(module => {
            if (module.isCheck) {
              this.role.moduleUuid.push(module.uuid)
              moduleName.push(module.name)
            }
            module.isCheck = false
          })
        })

        if (!moduleName.length) {
          this.$message.warning('请选取需要配置权限')
          return
        }

        this.role.moduleName = moduleName.join(',')

        if (this.role.uuid !== '') {
          roleUpdate(this.role).then(() => {
            this.$message.success('更新成功')
            this.roleCreateVisible = false
            this.role = {
              uuid: '',
              name: '',
              moduleUuid: [],
              moduleName: ''
            }
            this.loadRole()
          })
        } else {
          roleCreate(this.role).then(() => {
            this.$message.success('新增成功')
            this.roleCreateVisible = false
            this.role = {
              uuid: '',
              name: '',
              moduleUuid: [],
              moduleName: ''
            }
            this.loadRole()
          })

        }

      },

      handleDelete(role) {
        this.$confirm('删除角色将导致用户无法正常使用系统功能，是否继续删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          roleDelete({uuid: role.uuid}).then(() => {
            this.$message.success('删除成功')
            this.loadRole()
          })
        }).catch(() => {

        })
      }


    },
  }
</script>

<style lang="scss">
  .role {
    padding: $padding 0;

    .role-title {
      margin-bottom: $margin;
      .h3{
        margin-right: $margin;
      }
      .role-list-filter {
        text-align: right;
        padding-right: $margin;
      }
    }

    .role-container{
      margin: 0 $margin;
    }

    .module-container {
      .module-sort-item {
        .el-checkbox__inner {
          background-color: transparent;
        }
      }
    }

    .role-operate {
      span {
        display: inline-block;
        margin-right: $padding;
        cursor: pointer;
      }

      .user-edit {
        color: $btn_color;
      }

      .user-delete {
        color: $btn_sec_color;
      }
    }

  }
</style>
