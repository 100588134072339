<template>
    <div class="users">
        <div class="user-data">
            <el-table :data="users">
              <empty slot="empty"></empty>
                <el-table-column label="成员信息" align="center">
                    <template slot-scope="scope">
                        <div class="user-info">
                            <div class="user-info-img"><img :src="scope.row.avatar"/></div>
                            <div class="user-info-detail">
                                <p>{{scope.row.name}}</p>
                                <p>{{scope.row.phone}}</p>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="注册时间" align="center" prop="createdAt">
                    <template slot-scope="scope">
                    <span>
                        {{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}
                    </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
                @current-change="handleCurrentChange"
                class="pagination"
                :current-page="pagination.curPage"
                :page-size="pagination.limit"
                layout="total, prev, pager, next, jumper"
                :total="pagination.total">
        </el-pagination>

    </div>

</template>

<script>
    import {roleUserUnion, roleUserList} from  '@/api/account/role'
    export default {
        name: '',
        components: {

        },
        mounted: function () {
        },
        data: function () {
            return {
                users: [],
                editUserVisible: false,
                user: {
                    name: "",
                    avatar: "",
                    phone: "",
                    password: "",

                },
                pagination: {
                    start: 0,
                    curPage: 1,
                    limit: 10,
                    total: 0,
                    uuid: ''
                },
            }
        },
        methods: {
            loadUser(uuid) {
                this.pagination.uuid = uuid
                this.pagination.start = 0
                roleUserUnion(this.pagination).then(res => {
                    this.users = res.data.list || []
                    this.pagination.total = parseInt(res.data.total)
                })
            },
            handleCurrentChange(value){
                this.pagination.curPage = value
                this.pagination.start = (value - 1) * this.pagination.limit
                roleUserList(this.pagination).then(res => {
                    this.users = res.data
                })
            },
        },
    }
</script>

<style lang="scss">
    .users {
        height: 100%;
        display: flex;
        flex-direction: column;
        .user-data {
            flex: 1;

            .user-info {
                display: flex;
                justify-content: center;
                align-items: center;
                .user-info-img {
                    img {
                        max-width: 2rem;
                    }
                }
                .user-info-detail {
                    margin-left: $padding;
                    text-align: left;
                    p {
                        margin-bottom: $padding;
                    }
                    p:first-child {
                        font-size: .8rem;
                    }
                }
            }

            .el-table {
                thead {
                    tr {
                        th {
                            border-bottom: 1px solid ;
                        }
                    }
                }

            }
            .el-table--group::after, .el-table--border::after, .el-table::before {
                background-color: transparent;
            }
            .el-table__row:hover {
            }

            .user-edit, .user-delete {
                cursor: pointer;
                display: inline-block;
                margin-right: $padding;
            }
            .user-edit {
                color: $btn_color;
            }
            .user-delete {
                color: $btn_sec_color;
            }
        }

        .pagination {
            margin: $padding;
            text-align: right;
        }
    }

</style>
